import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class helperService {
  private userDetails: any;
  private picUrl: string;

  //Yishen Refenence - Source Lumen
  private valueToKey: { [key: number]: string } = {
    700: 'A',
    1400: 'B',
    1500: 'H',
    2000: 'J',
    3300: 'K',
    3900: 'L',
    4900: 'M',
  };

  constructor() {
    this.getUserDetails();
  }

  //nearestValue for above valueToKey
  getNearestKey(value: number): string | null {
    const nearestValue = Object.keys(this.valueToKey).reduce(
      (nearest: any, current: any) => {
        return Math.abs(+current - value) < Math.abs(nearest - value)
          ? +current
          : nearest;
      }
    );
    return this.valueToKey[parseInt(nearestValue)] || null;
  }

  getUserDetails(): void {
    const detailsString = localStorage.getItem('details');
    if (detailsString) {
      this.userDetails = JSON.parse(detailsString);
      if (this.userDetails?.picUrl) {
        this.picUrl = this.userDetails?.picUrl;
      }
    }
  }

  getUser(): any {
    this.getUserDetails();
    return this.userDetails;
  }

  getUserPicUrl(): string {
    this.getUserDetails();
    return this.picUrl;
  }

  // return selected downdown value object
  convertObject(list: any[], selectedValue: string): object | null {
    const option = list?.find((value: any) => value?.value === selectedValue);
    if (option) {
      const output = {
        _id: option?._id,
        name: option?.name,
        value: option?.value,
        refCode: option?.refCode,
      };
      return output;
    } else {
      return null;
    }
  }

  convertArrayObject(list: any[], selectedValues: string[]): any | null {
    const selectedOptions = [];
    for (const selectedValue of selectedValues) {
      const option = list?.find((value: any) => value.value === selectedValue);
      if (option) {
        selectedOptions.push({
          _id: option?._id,
          name: option?.name,
          value: option?.value,
          refCode: option?.refCode,
        });
      }
    }
    return selectedOptions;
  }

  __typenameRemoveObject(data: typenameItem) {
    const { __typename, ...newData } = data;
    return newData;
  }

  __typenameRemoveArray(data: any[]) {
    return data?.map(({ __typename, ...rest }) => rest);
  }

  convertedValueCRI(cri: string) {
    const value = cri;
    const convertedValue = 'CRI' + value?.substr(1);
    return convertedValue; // Output: CRI59
  }

  convertedValueSubCategory(subCategory: string) {
    let str = subCategory;
    str = str?.replace(/\s*\([A-Z]+\)\s*/g, '')?.trim();
    return str; // Output: Accessory & Components
  }

  convertedValueAccessories(accessories: string[]) {
    let str = accessories;
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].replace(/\s*\([A-Z]+\)\s*/g, '').trim();
    }
    return str; // Output: ["Anti Ligature", "Linear Wall Mounted"]
  }
}

export interface typenameItem {
  _id: string;
  name: string;
  value: string;
  __typename: string;
}
