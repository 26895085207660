import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { GraphqlService } from '../graphql.service';
import gql from 'graphql-tag';
import 'hammerjs';
import { ToastrService } from 'ngx-toastr';
import { ImageUploadServiceService } from '../image-upload-service.service';
import {
  CREATE_DATA_SHEET,
  DATA_SHEET_DETAILS,
  DOWNLOAD_DATA_SHEET,
  GET_CUSTOM_MASTER,
  MASTER,
  SEARCH_DATA_SHEET,
  UPDATE_DATA_SHEET,
} from '../helper/queries';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { helperService } from '../helper/helper';

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.scss'],
})
export class ProductdetailsComponent {
  isLoading: boolean = false;
  imgChangeEvt: any = '';
  cropImgPreview: any = '../../assets/images/noDataIcon.png';
  dataSheetForm: FormGroup;
  dataSheetFormSubmit: FormGroup;
  listvalue: any;
  openPdf: string;
  uploadImg: any;
  isSticky = false;

  dataSheetDetails: any;

  isReadOnly: boolean = true;

  @ViewChild('clearBtn') clearBtn: ElementRef;

  uploadImgType: string;
  @ViewChild('closeModal') closeModal: any;
  @ViewChild('openModalButton') openModal: any;
  getefficacyvalue: number;
  imgPopupfield: FormControl = new FormControl();

  @ViewChild('vendorSearch') vendorSearchInput: ElementRef;
  vendorOption: any;
  _vendorOption: any;
  loadingVendors: boolean = false;

  @ViewChild('symbolSearch') symbolSearchInput: ElementRef;
  symbolOption: any;
  _symbolOption: any;

  clickCount: number = 1;
  onAssClick() {
    this.clickCount++;
  }
  get countArray() {
    return Array(this.clickCount);
  }
  @ViewChild('selectSubsidiary') select: MatSelect;
  allSelectedSubsidiary = false;
  salesDescriptionFormat: string;

  searchQuery: string = '';
  dataSheetCode: string;
  dataSheetType: string;

  searchLoading: boolean = false;

  //serach
  control = new FormControl('');
  streets: string[] = [];
  filteredStreets: Observable<string[]>;

  //dropdownvalues
  dropDownValues: any = {
    type: null,
    vendor: null,
    subsidiary: null,
    representativeBrand: null,
    cri: null,
    subCategory: null,
    cct: null,
    protectionRating: null,
    impactRating: null,
    fittingColor: null,
    dimmingOptions: null,
    certifications: null,
    powerConnection: null,
    chipType: null,
    lightSourceChipType: null,
    accessories: null,
    energyClass: null,
    macAdam: null,
    warranty: {
      _id: '4',
      name: '5 Years',
      value: '1',
      refCode: null,
    },
    class: null,
    reflectorColour: null,
    opticalSystem: null,
  };

  // img drop
  @ViewChild('imageDropZone', { static: false }) imageDropZone: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private toastr: ToastrService,
    private imageUploadService: ImageUploadServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private _helper: helperService
  ) {
    this.isLoading = true;
    this.graphqlService.client
      .query({
        query: GET_CUSTOM_MASTER,
        variables: { typeName: 'vendor' },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        let sortedVendor : any[] = [];
        const activeVendors = data && data.getCustomMaster ? data?.getCustomMaster.filter((v : any) => !v.name.includes('DO NOT USE*')).sort((a : any, b : any) => a.name > b.name ? 1 : -1) : []
        const inactiveVendors = data && data.getCustomMaster ? data?.getCustomMaster.filter((v : any) => v.name.includes('DO NOT USE*')).sort((a : any, b : any) => a.name > b.name ? 1 : -1) : []

        sortedVendor.push(...activeVendors)
        sortedVendor.push(...inactiveVendors)

        this.loadingVendors = true;
        setTimeout(() => {
          this.vendorOption = sortedVendor; //data?.getCustomMaster;
          this.loadingVendors = false;
        }, 2000);
        this.vendorOption = sortedVendor; //data?.getCustomMaster;
        this._vendorOption = sortedVendor; //data?.getCustomMaster;
      })
      .catch((error: any) => {
        console.error(error);
      });

    this.graphqlService.client
      .query({
        query: MASTER,
        fetchPolicy: 'no-cache',
      })
      .then(({ data, loading }) => {
        this.listvalue = data?.master;

        // this.loadingVendors = true;
        // setTimeout(() => {
        //   this.vendorOption = data?.master?.vendor;
        //   this.loadingVendors = false;
        // }, 2000);

        // this.vendorOption = data?.master?.vendor;
        // this._vendorOption = data?.master?.vendor;
        this.symbolOption = data?.master?.symbol;
        this._symbolOption = data?.master?.symbol;

        this.isLoading = loading;

        this.route.queryParams.subscribe((params) => {
          this.dataSheetCode = params['code'];
          this.dataSheetType = params['type'];
        });
        if (this.dataSheetCode) {
          this.searchDatasheetDetails(this.dataSheetCode, 2);
        }
        if (this.dataSheetType === 'edit') {
          this.isReadOnly = false;
        }
        this.searchDatasheet();
      })
      .catch((error: any) => {
        this.isLoading = false;
        console.error(error);
      });
  }

  toggleAllSelectionSubsidiary() {
    if (this.allSelectedSubsidiary) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets?.filter((street) =>
      this._normalizeValue(street).includes(filterValue)
    );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  clearPopup(): void {
    this.imgPopupfield.reset();
  }

  clearUrl() {
    this.router.navigate(['/datasheet'], { replaceUrl: true });
  }

  // rangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
  //   const value = control.value;

  //   if (value !== null && value !== '' && (value < 700 || value > 4900)) {
  //     return { range: true };
  //   }

  //   return null;
  // }

  ngOnInit(): void {
    this.filteredStreets = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
    this.dataSheetForm = this._formBuilder.group({
      productName: [''],
      itemCode: ['', [Validators.required]],
      codePurchases: ['', [Validators.required]],
      displayName: ['', [Validators.required]],
      type: ['', [Validators.required]],
      representativeBrand: ['', [Validators.required]],
      modelNumber: [''],
      projectName: [''],
      subCategory: ['', [Validators.required]],
      typeNumber: [''],
      series: [''],
      purchaseDescription: [''],
      salesDescription: [''],
      sourcePower: [''],
      systemWattage: [''],
      material: [''],
      sourceLumen: [
        '',
        [
          Validators.pattern('^[0-9]*$'),
          // Validators.min(700),
          // Validators.max(4900),
        ],
      ],
      systemLumen: ['', [Validators.pattern('^[0-9]*$')]],
      // controlGear: [''],
      fittingColor: ['', [Validators.required]],
      protectionRating: [''],
      impactRating: [''],
      efficacy: [''],
      tilt: [''],
      diffuser: [''],
      // COICompatibility: [''],
      supplyVoltage: [''],
      powerConnection: [''],
      class: [''],
      LOR: ['', [Validators.pattern('^[0-9]*$')]],
      CRI: [''],
      CCT: ['', [Validators.required]],
      beamAngle: [''],
      UGR: [''],
      LEDLifetime: ['50000'],
      accessories: [''],
      dimmingOptions: [''],
      cutOut: [''],
      rotation: [''],
      macAdam: [''],
      diameter: [''],
      height: [''],
      length: ['', [Validators.pattern('^[0-9]+(.[0-9]+)?$')]],
      productLength: [''],
      // reflectorType: [''],
      reflectorColour: [''],
      lightSource: [''],
      chipType: [''],
      lightSourceChipType: [''],
      // warranty: ['5-Year Warranty'],
      warranty: ['1'],
      typeReference: [''],
      // installationMethod: [''],
      vendor: ['', [Validators.required]],
      purchasePrice: [''],
      subsidiary: [''],
      symbols: [[], [Validators.maxLength(8)]],
      inSituphoto: [''],
      productImage: [''],
      photometricImage: [''],
      measurementDiagram: [''],
      illuminanceConeDiagram: [''],
      otherRequirements: [''],

      // accessoriesImg: ['',],

      opticalSystem: [''],
      certifications: [''],
      width: ['', [Validators.pattern('^[0-9]+(.[0-9]+)?$')]],
      energyClass: [''],
      driverCode: [''],
      operatingTemp: [''],
      notes: [''],
    });

    const modelNumberControl = this.dataSheetForm.get('modelNumber');
    if (modelNumberControl) {
      modelNumberControl.valueChanges.subscribe(() => {
        this.codeGenerate();
      });
    }

    const typeControl = this.dataSheetForm.get('type');
    if (typeControl) {
      typeControl.valueChanges.subscribe((selectedValue) => {
        let output = this._helper.convertObject(
          this.listvalue?.type,
          selectedValue
        );
        this.dropDownValues.type = output;
      });
    }
    const representativeBrandControl = this.dataSheetForm.get(
      'representativeBrand'
    );
    if (representativeBrandControl) {
      representativeBrandControl.valueChanges.subscribe((selectedValue) => {
        let output = this._helper.convertObject(
          this.listvalue?.representativeBrand,
          selectedValue
        );
        this.dropDownValues.representativeBrand = output;
      });
    }

    const subCategoryControl = this.dataSheetForm.get('subCategory');
    if (subCategoryControl) {
      subCategoryControl.valueChanges.subscribe((selectedValue) => {

        const subcatValues = this.listvalue?.subCategory ? this.listvalue?.subCategory.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          subcatValues, //this.listvalue?.subCategory,
          selectedValue
        );
        this.dropDownValues.subCategory = output;
        this.codeGenerate();
      });
    }

    const LEDControl = this.dataSheetForm.get('LEDLifetime');
    if (LEDControl) {
      LEDControl.valueChanges.subscribe(() => {
        this.codeGenerate();
      });
    }
    const wattageControl = this.dataSheetForm.get('systemWattage');
    if (wattageControl) {
      wattageControl.valueChanges.subscribe(() => {
        this.codeGenerate();
      });
    }
    const systemLumenControl = this.dataSheetForm.get('systemLumen');
    if (systemLumenControl) {
      systemLumenControl.valueChanges.subscribe(() => {
        this.codeGenerate();
      });
    }
    const CRIControl = this.dataSheetForm.get('CRI');
    if (CRIControl) {
      CRIControl.valueChanges.subscribe((selectedValue) => {

        const criValues = this.listvalue?.cri ? this.listvalue?.cri.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          criValues, //this.listvalue?.cri,
          selectedValue
        );
        this.dropDownValues.cri = output;
        this.codeGenerate();
      });
    }
    const CCTControl = this.dataSheetForm.get('CCT');
    if (CCTControl) {
      CCTControl.valueChanges.subscribe((selectedValue) => {
        
        const cctValues = this.listvalue?.cct ? this.listvalue?.cct.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          cctValues, //this.listvalue?.cct,
          selectedValue
        );
        this.dropDownValues.cct = output;
        this.codeGenerate();
      });
    }
    const beamAngleControl = this.dataSheetForm.get('beamAngle');
    if (beamAngleControl) {
      beamAngleControl.valueChanges.subscribe(() => {
        this.codeGenerate();
      });
    }
    const accessoriesControl = this.dataSheetForm.get('accessories');
    if (accessoriesControl) {
      accessoriesControl.valueChanges.subscribe((selectedValues: string[]) => {

        const accessoriesValues = this.listvalue?.accessories ? this.listvalue?.accessories.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertArrayObject(
          accessoriesValues, //this.listvalue?.accessories,
          selectedValues
        );
        this.dropDownValues.accessories = output;
        this.codeGenerate();
      });
    }
    const dimmingOptionsControl = this.dataSheetForm.get('dimmingOptions');
    if (dimmingOptionsControl) {
      dimmingOptionsControl.valueChanges.subscribe(
        (selectedValues: string[]) => {

          const dimmingOptionsValues = this.listvalue?.dimmingOptions ? this.listvalue?.dimmingOptions.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

          let output = this._helper.convertArrayObject(
            dimmingOptionsValues, //this.listvalue?.dimmingOptions,
            selectedValues
          );
          this.dropDownValues.dimmingOptions = output;
          this.codeGenerate();
        }
      );
    }

    const fittingColorControl = this.dataSheetForm.get('fittingColor');
    if (fittingColorControl) {
      fittingColorControl.valueChanges.subscribe((selectedValue) => {

        const fittingColorValues = this.listvalue?.fittingColor ? this.listvalue?.fittingColor.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          fittingColorValues, //this.listvalue?.fittingColor,
          selectedValue
        );
        this.dropDownValues.fittingColor = output;
        this.codeGenerate();
      });
    }

    const protectionRatingControl = this.dataSheetForm.get('protectionRating');
    if (protectionRatingControl) {
      protectionRatingControl.valueChanges.subscribe((selectedValue) => {
        let output = this._helper.convertObject(
          this.listvalue?.protectionRating,
          selectedValue
        );
        this.dropDownValues.protectionRating = output;
        this.codeGenerate();
      });
    }
    const impactRatingControl = this.dataSheetForm.get('impactRating');
    if (impactRatingControl) {
      impactRatingControl.valueChanges.subscribe((selectedValue) => {
        let output = this._helper.convertObject(
          this.listvalue?.impactRating,
          selectedValue
        );
        this.dropDownValues.impactRating = output;
        this.codeGenerate();
      });
    }
    const chipTypeControl = this.dataSheetForm.get('chipType');
    if (chipTypeControl) {
      chipTypeControl.valueChanges.subscribe((selectedValue) => {

        const chipTypeValues = this.listvalue?.chipType ? this.listvalue?.chipType.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          chipTypeValues, //this.listvalue?.chipType,
          selectedValue
        );
        this.dropDownValues.chipType = output;
        this.codeGenerate();
      });
    }
    const lightSourceChipTypeControl = this.dataSheetForm.get(
      'lightSourceChipType'
    );
    if (lightSourceChipTypeControl) {
      lightSourceChipTypeControl.valueChanges.subscribe((selectedValue) => {

        const lightSourceChipTypeValues = this.listvalue?.lightSourceChipType ? this.listvalue?.lightSourceChipType.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          lightSourceChipTypeValues, //this.listvalue?.lightSourceChipType,
          selectedValue
        );
        this.dropDownValues.lightSourceChipType = output;
        this.codeGenerate();
      });
    }
    const vendorControl = this.dataSheetForm.get('vendor');
    if (vendorControl) {
      vendorControl.valueChanges.subscribe((selectedValue) => {
        let output = this._helper.convertObject(
          this.vendorOption,
          selectedValue
        );
        this.dropDownValues.vendor = output;
        this.codeGenerate();
      });
    }
    const sourceLumenControl = this.dataSheetForm.get('sourceLumen');
    if (sourceLumenControl) {
      sourceLumenControl.valueChanges.subscribe(() => {
        this.codeGenerate();
      });
    }

    const driverCodeControl = this.dataSheetForm.get('driverCode');
    if (driverCodeControl) {
      driverCodeControl.valueChanges.subscribe(() => {
        this.codeGenerate();
      });
    }

    const subsidiaryControl = this.dataSheetForm.get('subsidiary');
    if (subsidiaryControl) {
      subsidiaryControl.valueChanges.subscribe((selectedValues: string[]) => {

        const subsidiaryValues = this.listvalue?.subsidiary ? this.listvalue?.subsidiary.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertArrayObject(
          subsidiaryValues,
          selectedValues
        );
        this.dropDownValues.subsidiary = output;
      });
    }

    const certificationsControl = this.dataSheetForm.get('certifications');
    if (certificationsControl) {
      certificationsControl.valueChanges.subscribe(
        (selectedValues: string[]) => {

          const certificationsValues = this.listvalue?.certifications ? this.listvalue?.certifications.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];
          
          let output = this._helper.convertArrayObject(
            certificationsValues, //this.listvalue?.certifications,
            selectedValues
          );
          this.dropDownValues.certifications = output;
          this.codeGenerate();
        }
      );
    }

    const powerConnectionControl = this.dataSheetForm.get('powerConnection');
    if (powerConnectionControl) {
      powerConnectionControl.valueChanges.subscribe((selectedValue) => {

        const powerConnectionValues = this.listvalue?.powerConnection ? this.listvalue?.powerConnection.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          powerConnectionValues, //this.listvalue?.powerConnection,
          selectedValue
        );
        this.dropDownValues.powerConnection = output;
        this.codeGenerate();
      });
    }

    const energyClassControl = this.dataSheetForm.get('energyClass');
    if (energyClassControl) {
      energyClassControl.valueChanges.subscribe((selectedValue) => {

        const energyClassValues = this.listvalue?.energyClass ? this.listvalue?.energyClass.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          energyClassValues, //this.listvalue?.energyClass,
          selectedValue
        );
        this.dropDownValues.energyClass = output;
        this.codeGenerate();
      });
    }

    const macAdamControl = this.dataSheetForm.get('macAdam');
    if (macAdamControl) {
      macAdamControl.valueChanges.subscribe((selectedValue) => {

        const macadamValues = this.listvalue?.macadam ? this.listvalue?.macadam.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          macadamValues, //this.listvalue?.macadam,
          selectedValue
        );
        this.dropDownValues.macAdam = output;
      });
    }

    const warrantyControl = this.dataSheetForm.get('warranty');
    if (warrantyControl) {
      warrantyControl.valueChanges.subscribe((selectedValue) => {

        const warrantyValues = this.listvalue?.warranty ? this.listvalue?.warranty.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          warrantyValues, //this.listvalue?.warranty,
          selectedValue
        );
        this.dropDownValues.warranty = output;
      });
    }

    const classControl = this.dataSheetForm.get('class');
    if (classControl) {
      classControl.valueChanges.subscribe((selectedValue) => {
        
        const classOfProtectionValues = this.listvalue?.classOfProtection ? this.listvalue?.classOfProtection.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          classOfProtectionValues, //this.listvalue?.classOfProtection,
          selectedValue
        );
        this.dropDownValues.class = output;
      });
    }

    const reflectorColourControl = this.dataSheetForm.get('reflectorColour');
    if (reflectorColourControl) {
      reflectorColourControl.valueChanges.subscribe((selectedValue) => {

        const reflectorBaffleColourValues = this.listvalue?.reflectorBaffleColour ? this.listvalue?.reflectorBaffleColour.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];
        
        let output = this._helper.convertObject(
          reflectorBaffleColourValues, //this.listvalue?.reflectorBaffleColour,
          selectedValue
        );
        this.dropDownValues.reflectorColour = output;
      });
    }

    const opticalSystemControl = this.dataSheetForm.get('opticalSystem');
    if (opticalSystemControl) {
      opticalSystemControl.valueChanges.subscribe((selectedValue) => {

        const opticalSystemValues = this.listvalue?.opticalSystem ? this.listvalue?.opticalSystem.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertObject(
          opticalSystemValues, //this.listvalue?.opticalSystem,
          selectedValue
        );
        this.dropDownValues.opticalSystem = output;
      });
    }

    const productNameControl = this.dataSheetForm.get('productName');
    if (productNameControl) {
      productNameControl.valueChanges.subscribe(() => {
        this.codeGenerate();
      });
    }
  }

  findNearestKey(value: number) {
    const nearestKey = this._helper.getNearestKey(value);
    return nearestKey;
  }

  codeGenerate() {
    const modelNumber = this.dataSheetForm.get('modelNumber');
    const subCategory = this.dataSheetForm.get('subCategory');
    const LED = this.dataSheetForm.get('LEDLifetime');
    const wattage = this.dataSheetForm.get('systemWattage');
    const systemLumen = this.dataSheetForm.get('systemLumen');
    const CRI = this.dataSheetForm.get('CRI');
    const CCT = this.dataSheetForm.get('CCT');
    const beamAngle = this.dataSheetForm.get('beamAngle');
    const fittingColor = this.dataSheetForm.get('fittingColor');
    const dimmingOptions = this.dataSheetForm.get('dimmingOptions');
    const driverCode = this.dataSheetForm.get('driverCode');

    const IP = this.dataSheetForm.get('protectionRating');
    const IK = this.dataSheetForm.get('impactRating');
    const chipType = this.dataSheetForm.get('chipType');
    const lightSourceChipType = this.dataSheetForm.get('lightSourceChipType');

    const accessories = this.dataSheetForm.get('accessories');

    const sourceLumen = this.dataSheetForm.get('sourceLumen');

    const productName = this.dataSheetForm.get('productName');

    let sourceLumenValue = '';
    // sourceLumenValue key value finder
    if (sourceLumen) {
      sourceLumenValue =
        sourceLumen?.value &&
        this?.findNearestKey(parseInt(sourceLumen?.value));
    }

    let productCode;
    let salesCode;
    let purchaseCode;

    const vendor = this.dataSheetForm.get('vendor');

    if (vendor && this.dropDownValues?.vendor?.name.includes('Yishen')) {
      // Product code generation YIS

      productCode = 'YIS';

      if (subCategory && subCategory.value) {
        productCode += '-' + this.dropDownValues?.subCategory?.refCode;
      }
      if (modelNumber && modelNumber.value) {
        productCode += '-' + modelNumber.value;
      }
      if (sourceLumen && sourceLumen.value) {
        productCode += '-' + sourceLumenValue;
      }
      if (lightSourceChipType && lightSourceChipType.value) {
        if (!sourceLumen?.value) {
          productCode += '-' + this.dropDownValues.lightSourceChipType?.refCode;
        } else {
          productCode += this.dropDownValues.lightSourceChipType?.refCode;
        }
      }
      if (CRI && CRI.value) {
        productCode += '-' + this.dropDownValues?.cri?.refCode;
      }
      if (CCT && CCT.value) {
        if (!CRI?.value) {
          productCode += '-' + this.dropDownValues?.cct?.refCode;
        } else {
          productCode += this.dropDownValues?.cct?.refCode;
        }
      }
      if (beamAngle && beamAngle.value) {
        productCode += '-' + beamAngle.value + 'D';
      }
      if (fittingColor && fittingColor.value) {
        productCode += '-' + this.dropDownValues?.fittingColor?.refCode;
      }
      if (accessories && accessories.value) {
        productCode +=
          '-' +
          this.dropDownValues?.accessories
            ?.map((item: any) => item?.refCode)
            ?.join('');
      }
      if (dimmingOptions && dimmingOptions.value) {
        if (!accessories?.value) {
          productCode +=
            '-' +
            this.dropDownValues?.dimmingOptions
              ?.map((item: any) => item?.refCode)
              ?.join('');
        } else {
          productCode += this.dropDownValues?.dimmingOptions
            ?.map((item: any) => item?.refCode)
            ?.join('');
        }
      }
      if (productCode.endsWith('-NA')) {
        productCode = productCode.slice(0, -3);
      } else if (productCode.endsWith('NA')) {
        productCode = productCode.slice(0, -2);
      } else if (productCode.endsWith('-NA-')) {
        productCode = productCode.slice(0, -4);
      } else if (productCode.endsWith('-NA-NA-')) {
        productCode = productCode.slice(0, -7);
      } else if (productCode.endsWith(',')) {
        productCode = productCode.slice(0, -1);
      }
      productCode = productCode?.replace(/--/g, '-');
      productCode = productCode.replace(/-+$/, '');
      productCode = productCode.replace(/,+$/, '');
      productCode = productCode.replace(/,/g, '');
      productCode = productCode.replace(/[\s,]+/g, '');

      // Sale code generation YIS
      salesCode = '';
      if (productName && productName?.value) {
        salesCode += productName?.value + ' ';
      }
      if (subCategory && subCategory.value) {
        salesCode += this.dropDownValues?.subCategory?.refCode;
      }
      if (modelNumber && modelNumber.value) {
        salesCode += '-' + modelNumber.value + ' ';
      }
      if (subCategory && subCategory.value) {
        salesCode +=
          ' ' +
          this._helper?.convertedValueSubCategory(
            this.dropDownValues?.subCategory?.name
          ) +
          ', ';
      }
      if (wattage && wattage.value) {
        salesCode += wattage.value + 'W' + ', ';
      }
      if (sourceLumen && sourceLumen.value) {
        salesCode += sourceLumen.value + 'LM' + ', ';
      }
      if (CRI && CRI.value) {
        salesCode +=
          this._helper?.convertedValueCRI(this.dropDownValues?.cri?.name) +
          ', ';
      }
      if (CCT && CCT.value) {
        salesCode += this.dropDownValues?.cct?.name + ', ';
      }
      if (beamAngle && beamAngle.value) {
        salesCode += beamAngle.value + 'D' + ', ';
      }
      if (fittingColor && fittingColor.value) {
        salesCode += this.dropDownValues?.fittingColor?.name + ' Finish' + ', ';
      }
      if (accessories && accessories.value) {
        salesCode +=
          this._helper
            ?.convertedValueAccessories(
              this.dropDownValues?.accessories?.map((item: any) => item?.name)
            )
            ?.join(', ') + ', ';
      }
      if (dimmingOptions && dimmingOptions.value) {
        let dimmingValues = this.dropDownValues?.dimmingOptions
          ?.map((item: any) => item?.name)
          ?.join(', ');
        if (
          dimmingValues &&
          !dimmingValues.toLowerCase().includes('non-dimmable')
        ) {
          dimmingValues += ' Dimmable, ';
        }
        salesCode += dimmingValues;
      }

      salesCode = salesCode?.replace(/--/g, '-').replace(/ {2,}/g, ' ');

      // Purchase code generation YIS
      purchaseCode = '';

      if (productName && productName?.value) {
        purchaseCode += productName?.value + ' ';
      }
      if (subCategory && subCategory.value) {
        purchaseCode += this.dropDownValues?.subCategory?.refCode;
      }
      if (modelNumber && modelNumber.value) {
        purchaseCode += '-' + modelNumber.value + ' ';
      }
      if (subCategory && subCategory.value) {
        purchaseCode +=
          ' ' +
          this._helper?.convertedValueSubCategory(
            this.dropDownValues?.subCategory?.name
          ) +
          ', ';
      }
      if (wattage && wattage.value) {
        purchaseCode += wattage.value + 'W' + ', ';
      }
      if (sourceLumen && sourceLumen.value) {
        purchaseCode += sourceLumen.value + 'LM' + ', ';
      }
      if (CRI && CRI.value) {
        purchaseCode +=
          this._helper?.convertedValueCRI(this.dropDownValues?.cri?.name) +
          ', ';
      }
      if (CCT && CCT.value) {
        purchaseCode += this.dropDownValues?.cct?.name + ', ';
      }
      if (beamAngle && beamAngle.value) {
        purchaseCode += beamAngle.value + 'D' + ', ';
      }
      if (fittingColor && fittingColor.value) {
        purchaseCode +=
          this.dropDownValues?.fittingColor?.name + ' Finish' + ', ';
      }
      if (accessories && accessories.value) {
        purchaseCode +=
          this._helper
            ?.convertedValueAccessories(
              this.dropDownValues?.accessories?.map((item: any) => item?.name)
            )
            ?.join(', ') + ', ';
      }
      if (dimmingOptions && dimmingOptions.value) {
        let dimmingValues = this.dropDownValues?.dimmingOptions
          ?.map((item: any) => item?.name)
          ?.join(', ');
        if (
          dimmingValues &&
          !dimmingValues.toLowerCase().includes('non-dimmable')
        ) {
          dimmingValues += ' Dimmable, ';
        }
        purchaseCode += dimmingValues + ' ';
      }
      if (IP && IP.value) {
        purchaseCode += this.dropDownValues?.protectionRating?.name + ', ';
      }
      if (IK && IK.value) {
        const impactRatingName = this.dropDownValues?.impactRating?.name;

        if (impactRatingName && impactRatingName !== 'NA') {
          purchaseCode += impactRatingName + ', ';
        }
      }
      if (lightSourceChipType && lightSourceChipType.value) {
        purchaseCode +=
          this.dropDownValues.lightSourceChipType?.name + ' Chip' + ', ';
      }
      if (driverCode && driverCode.value) {
        purchaseCode += driverCode.value;
      } else {
        purchaseCode += '*ENTERDRIVERBRAND*';
      }

      purchaseCode = purchaseCode?.replace(/--/g, '-').replace(/ {2,}/g, ' ');
    } else {
      // Product code generation LPA
      productCode = 'LPA';

      if (subCategory && subCategory.value) {
        productCode += '-' + this.dropDownValues?.subCategory?.refCode;
      }
      if (modelNumber && modelNumber.value) {
        productCode += '-' + modelNumber.value;
      }
      if (wattage && wattage.value) {
        productCode += '-' + wattage.value;
      }
      if (CRI && CRI.value) {
        productCode += '-' + this.dropDownValues?.cri?.refCode;
      }
      if (CCT && CCT.value) {
        if (!CRI?.value) {
          productCode += '-' + this.dropDownValues?.cct?.refCode;
        } else {
          productCode += this.dropDownValues?.cct?.refCode;
        }
      }
      if (beamAngle && beamAngle.value) {
        productCode += '-' + beamAngle.value + 'D';
      }
      if (fittingColor && fittingColor.value) {
        productCode += '-' + this.dropDownValues?.fittingColor?.refCode;
      }
      if (accessories && accessories.value) {
        productCode +=
          '-' +
          this.dropDownValues?.accessories
            ?.map((item: any) => item?.refCode)
            ?.join('');
      }
      if (dimmingOptions && dimmingOptions.value) {
        if (!accessories?.value) {
          productCode +=
            '-' +
            this.dropDownValues?.dimmingOptions
              ?.map((item: any) => item?.refCode)
              ?.join('');
        } else {
          productCode += this.dropDownValues?.dimmingOptions
            ?.map((item: any) => item?.refCode)
            ?.join(', ');
        }
      }
      if (productCode.endsWith('-NA')) {
        productCode = productCode.slice(0, -3);
      } else if (productCode.endsWith('NA')) {
        productCode = productCode.slice(0, -2);
      } else if (productCode.endsWith('-NA-')) {
        productCode = productCode.slice(0, -4);
      } else if (productCode.endsWith('-NA-NA-')) {
        productCode = productCode.slice(0, -7);
      } else if (productCode.endsWith(',')) {
        productCode = productCode.slice(0, -1);
      }
      productCode = productCode?.replace(/--/g, '-');
      productCode = productCode.replace(/-+$/, '');
      productCode = productCode.replace(/,+$/, '');
      productCode = productCode.replace(/,/g, '');
      productCode = productCode.replace(/[\s,]+/g, '');

      // Sale code generation LPA
      salesCode = '';

      if (productName && productName?.value) {
        salesCode += productName?.value + ' ';
      }
      if (subCategory && subCategory.value) {
        salesCode += this.dropDownValues?.subCategory?.refCode;
      }
      if (modelNumber && modelNumber.value) {
        salesCode += '-' + modelNumber.value + ' ';
      }
      if (subCategory && subCategory.value) {
        salesCode +=
          ' ' +
          this._helper?.convertedValueSubCategory(
            this.dropDownValues?.subCategory?.name
          ) +
          ', ';
      }
      if (wattage && wattage.value) {
        salesCode += wattage.value + 'W' + ', ';
      }
      if (systemLumen && systemLumen.value) {
        salesCode += systemLumen.value + 'LM' + ', ';
      }
      if (CRI && CRI.value) {
        salesCode +=
          this._helper?.convertedValueCRI(this.dropDownValues?.cri?.name) +
          ', ';
      }
      if (CCT && CCT.value) {
        salesCode += this.dropDownValues?.cct?.name + ', ';
      }
      if (beamAngle && beamAngle.value) {
        salesCode += beamAngle.value + 'D' + ', ';
      }
      if (fittingColor && fittingColor.value) {
        salesCode += this.dropDownValues?.fittingColor?.name + ' Finish' + ', ';
      }
      if (accessories && accessories.value) {
        salesCode +=
          this._helper
            ?.convertedValueAccessories(
              this.dropDownValues?.accessories?.map((item: any) => item?.name)
            )
            ?.join(', ') + ', ';
      }
      if (dimmingOptions && dimmingOptions.value) {
        let dimmingValues = this.dropDownValues?.dimmingOptions
          ?.map((item: any) => item?.name)
          ?.join(', ');
        if (
          dimmingValues &&
          !dimmingValues.toLowerCase().includes('non-dimmable')
        ) {
          dimmingValues += ' Dimmable, ';
        }
        salesCode += dimmingValues;
      }

      salesCode = salesCode?.replace(/--/g, '-').replace(/ {2,}/g, ' ');

      // Purchase code generation LPA
      purchaseCode = '';

      if (productName && productName?.value) {
        purchaseCode += productName?.value + ' ';
      }
      if (subCategory && subCategory.value) {
        purchaseCode += this.dropDownValues?.subCategory?.refCode;
      }
      if (modelNumber && modelNumber.value) {
        purchaseCode += '-' + modelNumber.value + ' ';
      }
      if (subCategory && subCategory.value) {
        purchaseCode +=
          ' ' +
          this._helper?.convertedValueSubCategory(
            this.dropDownValues?.subCategory?.name
          ) +
          ', ';
      }
      if (wattage && wattage.value) {
        purchaseCode += wattage.value + 'W' + ', ';
      }
      if (systemLumen && systemLumen.value) {
        purchaseCode += systemLumen.value + 'LM' + ', ';
      }
      if (CRI && CRI.value) {
        purchaseCode +=
          this._helper?.convertedValueCRI(this.dropDownValues?.cri?.name) +
          ', ';
      }
      if (CCT && CCT.value) {
        purchaseCode += this.dropDownValues?.cct?.name + ', ';
      }
      if (beamAngle && beamAngle.value) {
        purchaseCode += beamAngle.value + 'D' + ', ';
      }
      if (fittingColor && fittingColor.value) {
        purchaseCode +=
          this.dropDownValues?.fittingColor?.name + ' Finish' + ', ';
      }
      if (accessories && accessories.value) {
        purchaseCode +=
          this._helper
            ?.convertedValueAccessories(
              this.dropDownValues?.accessories?.map((item: any) => item?.name)
            )
            ?.join(', ') + ', ';
      }
      if (dimmingOptions && dimmingOptions.value) {
        if (dimmingOptions && dimmingOptions.value) {
          let dimmingValues = this.dropDownValues?.dimmingOptions
            ?.map((item: any) => item?.name)
            ?.join(', ');
          if (
            dimmingValues &&
            !dimmingValues.toLowerCase().includes('non-dimmable')
          ) {
            dimmingValues += ' Dimmable, ';
          }
          purchaseCode += dimmingValues + ' ';
        }
      }
      if (IP && IP.value) {
        purchaseCode += this.dropDownValues?.protectionRating?.name + ', ';
      }
      if (IK && IK.value) {
        const impactRatingName = this.dropDownValues?.impactRating?.name;

        if (impactRatingName && impactRatingName !== 'NA') {
          purchaseCode += impactRatingName + ', ';
        }
      }
      if (lightSourceChipType && lightSourceChipType.value) {
        purchaseCode +=
          this.dropDownValues.lightSourceChipType?.name + ' Chip' + ', ';
      }
      if (driverCode && driverCode.value) {
        purchaseCode += driverCode.value;
      } else {
        purchaseCode += '*ENTERDRIVERBRAND*';
      }

      purchaseCode = purchaseCode?.replace(/--/g, '-').replace(/ {2,}/g, ' ');
    }

    this.dataSheetForm.controls['itemCode'].setValue(productCode);

    this.dataSheetForm.controls['salesDescription'].setValue(salesCode);

    this.dataSheetForm.controls['purchaseDescription'].setValue(purchaseCode);
  }

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }

  onDragOver(event: Event): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    this.imgChangeEvt = event;
    if (event.dataTransfer) {
      if (event.dataTransfer.files.length > 0) {
        const file = event.dataTransfer.files[0];
        if (file.type.startsWith('image/')) {
          this.onFileChange(file);
        } else {
          // Handle non-image file drops
          console.error('Please drop an image file.');
        }
      }
    }
  }

  onDropOut(event: DragEvent, type: string): void {
    this.openModal.nativeElement.click();
    this.imgChangeEvt = event;
    this.uploadImgType = type;
    if (event.dataTransfer) {
      if (event.dataTransfer.files.length > 0) {
        const file = event.dataTransfer.files[0];
        if (file.type.startsWith('image/')) {
          this.onFileChange(file);
        } else {
          // Handle non-image file drops
          console.error('Please drop an image file.');
        }
      }
    }
  }

  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
    this.uploadImg = e.blob;
  }
  imgLoad() {
    // display cropper tool
  }
  initCropper() {
    // init cropper
  }
  imgFailed() {
    // error msg
  }

  // image zoom in and zoom out
  scale = 1;
  transform: ImageTransform = {};
  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toFloat(value: string): number {
    return parseFloat(value);
  }

  clearImg(type: string): void {
    this.dataSheetForm.controls[type].setValue('');
  }
  clearImgAss(index: number): void {
    // this.dataSheetForm.controls['accessoriesImg'].setValue('');
    const indexToRemove = index;
    const accessoriesImgControl = this.dataSheetForm.get('accessoriesImg');
    if (
      accessoriesImgControl &&
      indexToRemove >= 0 &&
      indexToRemove < accessoriesImgControl.value.length
    ) {
      const updatedValue = [...accessoriesImgControl.value];
      updatedValue.splice(indexToRemove, 1);
      accessoriesImgControl.setValue(updatedValue);
    }
  }

  openModel(type: string): void {
    this.cropImgPreview = '../../assets/images/noDataIcon.png';
    this.imgChangeEvt = null;
    this.uploadImg = null;
    this.uploadImgType = type;
    this.openModal.nativeElement.click();
  }

  enableEdit(): void {
    this.dataSheetType = 'edit';
    this.isReadOnly = false;
  }

  uploadImage(): any {
    if (this.uploadImg) {
      this.isLoading = true;
      this.imageUploadService
        .uploadImage(this.uploadImg)
        .then((downloadURL) => {
          this.isLoading = false;
          // if (this.uploadImgType === 'accessoriesImg') {
          //   const accessoriesImgControl = this.dataSheetForm.get('accessoriesImg') as FormControl
          //   const currentValues = accessoriesImgControl.value || [];
          //   currentValues.push(downloadURL);
          //   accessoriesImgControl.patchValue(currentValues);
          //   this.imgPopupfield.reset();
          //   this.closeModal.nativeElement.click();
          // } else {
          this.dataSheetForm.controls[this.uploadImgType].setValue(downloadURL);
          this.imgPopupfield.reset();
          this.closeModal.nativeElement.click();
          // }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error('Error uploading image:', error);
        });
    }
  }

  // logValidationErrors(group: FormGroup): void {
  //   Object.keys(group.controls).forEach(key => {
  //     const control = group.get(key);
  //     if (control instanceof FormGroup) {
  //       this.logValidationErrors(control);
  //     } else {
  //       if (control && control.invalid) {
  //         const errors = control.errors;
  //         if (errors) {
  //           console.log(`Control: ${key}, Errors: `, errors);
  //         }
  //       }
  //     }
  //   });
  // }

  dataSheetSubmit(): void {
    // this.logValidationErrors(this.dataSheetForm);
    if (this.dataSheetForm.valid) {
      if (this.dataSheetDetails?.netSuiteId && this.dataSheetType === 'edit') {
        const value = this.dataSheetForm.value;
        this.isLoading = true;
        this.graphqlService.client
          .mutate({
            mutation: UPDATE_DATA_SHEET,
            variables: {
              nestuiteId: this.dataSheetDetails?.netSuiteId,
              updateDataSheetInput: {
                id: this.dataSheetDetails?._id,
                productName: value?.productName,
                itemCode: value?.itemCode,
                type:
                  this.dropDownValues?.type ||
                  (this.dataSheetDetails?.type
                    ? this._helper?.__typenameRemoveObject(
                        this.dataSheetDetails?.type
                      )
                    : ''),
                displayName: value?.displayName,
                codePurchases: value?.codePurchases,
                representativebrand:
                  this.dropDownValues?.representativeBrand ||
                  (this?.dataSheetDetails?.representativebrand
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.representativebrand
                      )
                    : ''),
                modelNumber: value?.modelNumber,
                projectName: value?.projectName,
                subCategory:
                  this.dropDownValues?.subCategory ||
                  (this?.dataSheetDetails?.subCategory
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.subCategory
                      )
                    : ''),
                typeNumber: value?.typeNumber,
                series: value?.series,
                purchaseDesc: value?.purchaseDescription,
                salesDesc: value?.salesDescription,
                systemPower: value?.systemWattage?.toString(),
                sourcePower: value?.sourcePower?.toString(),
                material: value?.material,
                sourceLumens: value?.sourceLumen,
                systemLumens: value?.systemLumen?.toString(),
                fittingColour:
                  this.dropDownValues?.fittingColor ||
                  (this?.dataSheetDetails?.fittingColour
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.fittingColour
                      )
                    : ''),
                protectionRating:
                  this.dropDownValues?.protectionRating ||
                  (this?.dataSheetDetails?.protectionRating
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.protectionRating
                      )
                    : null),
                impactRating:
                  this.dropDownValues?.impactRating ||
                  (this?.dataSheetDetails?.impactRating
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.impactRating
                      )
                    : null),
                efficacy: value?.efficacy?.toString(),
                tilt: value?.tilt,
                diffuser: value?.diffuser,
                supplyVoltage: value?.supplyVoltage,
                powerConnection:
                  this.dropDownValues?.powerConnection ||
                  (this?.dataSheetDetails?.powerConnection
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.powerConnection
                      )
                    : null),
                class:
                  this.dropDownValues?.class ||
                  (this?.dataSheetDetails?.class
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.class
                      )
                    : null),
                lOR: value?.LOR,
                cri:
                  this.dropDownValues?.cri ||
                  (this?.dataSheetDetails?.cri
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.cri
                      )
                    : null),
                cct:
                  this.dropDownValues?.cct ||
                  (this?.dataSheetDetails?.cct
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.cct
                      )
                    : ''),
                beamAngle: value?.beamAngle,
                ugr: value?.UGR,
                ledLifeTime: value?.LEDLifetime,
                dimmingOptions:
                  this.dropDownValues?.dimmingOptions?.length === 0
                    ? this?.dataSheetDetails?.dimmingOptions
                      ? this._helper?.__typenameRemoveArray(
                          this?.dataSheetDetails?.dimmingOptions
                        )
                      : null
                    : this.dropDownValues?.dimmingOptions,
                accessories:
                  this.dropDownValues?.accessories?.length === 0
                    ? this.dataSheetDetails?.accessories
                      ? this._helper?.__typenameRemoveArray(
                          this.dataSheetDetails?.accessories
                        )
                      : null
                    : this._helper?.__typenameRemoveArray(
                        this.dataSheetDetails?.accessories
                      ),
                cutOut: value?.cutOut,
                rotation: value?.rotation,
                macAdam:
                  this.dropDownValues?.macAdam ||
                  (this?.dataSheetDetails?.macAdam
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.macAdam
                      )
                    : null),
                diameter: value?.diameter,
                height: value?.height,
                length: value?.length,
                reflectorColour:
                  this.dropDownValues?.reflectorColour ||
                  (this?.dataSheetDetails?.reflectorColour
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.reflectorColour
                      )
                    : null),
                lightSource: value?.lightSource,
                chiptype:
                  this.dropDownValues?.chipType ||
                  (this?.dataSheetDetails?.chiptype
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.chiptype
                      )
                    : null),
                lightSourceChipType:
                  this.dropDownValues?.lightSourceChipType ||
                  (this?.dataSheetDetails?.lightSourceChipType
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.lightSourceChipType
                      )
                    : null),
                warranty:
                  this.dropDownValues?.warranty ||
                  (this?.dataSheetDetails?.warranty
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.warranty
                      )
                    : null),
                typeReference: value?.typeReference,
                vendor:
                  this.dropDownValues?.vendor ||
                  (this?.dataSheetDetails?.vendor
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.vendor
                      )
                    : ''),
                purchasePrice: value?.purchasePrice?.toString(),
                subsidiary:
                  this.dropDownValues?.subsidiary?.length === 0
                    ? this.dataSheetDetails?.subsidiary
                      ? this._helper?.__typenameRemoveArray(
                          this.dataSheetDetails?.subsidiary
                        )
                      : null
                    : // : this.dataSheetDetails?.subsidiary,
                      this._helper?.__typenameRemoveArray(
                        this.dataSheetDetails?.subsidiary
                      ),
                symbols: value?.symbols,
                inSituphoto: value?.inSituphoto,
                productImage: value?.productImage,
                photometricImage: value?.photometricImage,
                measurementDiagram: value?.measurementDiagram,
                IlluminanceConeDiagram: value?.illuminanceConeDiagram,
                OtherRequirements: value?.otherRequirements,
                opticalSystem:
                  this.dropDownValues?.opticalSystem ||
                  (this?.dataSheetDetails?.opticalSystem
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.opticalSystem
                      )
                    : null),
                certifications:
                  this.dropDownValues?.certifications?.length === 0
                    ? this.dataSheetDetails?.certifications
                      ? this._helper?.__typenameRemoveArray(
                          this.dataSheetDetails?.certifications
                        )
                      : null
                    : // : this.dataSheetDetails?.certifications,
                      this._helper?.__typenameRemoveArray(
                        this.dataSheetDetails?.certifications
                      ),

                width: value?.width,
                energyClass:
                  this.dropDownValues?.energyClass ||
                  (this?.dataSheetDetails?.energyClass
                    ? this._helper?.__typenameRemoveObject(
                        this?.dataSheetDetails?.energyClass
                      )
                    : null),
                driverCode: value?.driverCode,
                operatingTemp: value?.operatingTemp,
                notes: value?.notes,
                productLength: value?.productLength,
              },
            },
            fetchPolicy: 'no-cache',
          })
          .then((result: any) => {
            if (result?.data?.updateDataSheet?._id) {
              const id = result?.data?.updateDataSheet?._id;
              this.graphqlService.client
                .mutate({
                  mutation: DOWNLOAD_DATA_SHEET,
                  variables: { id: id },
                  fetchPolicy: 'network-only',
                })
                .then((result) => {
                  this.isLoading = false;
                  this.openPdf = result?.data?.downloadDataSheet?.url;
                  this.toastr.success('Datasheet is generated...');
                  // this.dataSheetForm.reset();
                  this.clearBtn.nativeElement.click();
                  // this.clearForm();
                  this.clearUrl();
                  this.openDownload();
                })
                .catch((error: any) => {
                  this.isLoading = false;
                  this.toastr.error(error.message);
                  console.error(error);
                });
            } else {
              this.isLoading = false;
              this.toastr.error('Something Went Wrong... Try Again');
            }
          })
          .catch((error: any) => {
            this.isLoading = false;
            this.toastr.error(error.message);
            console.error(error);
          });
      } else {
        const value = this.dataSheetForm.value;
        this.isLoading = true;
        this.graphqlService.client
          .mutate({
            mutation: CREATE_DATA_SHEET,
            variables: {
              createDataSheetInput: {
                productName: value?.productName,
                itemCode: value?.itemCode,
                type: this.dropDownValues.type,
                displayName: value?.displayName,
                codePurchases: value?.codePurchases,
                representativebrand: this.dropDownValues.representativeBrand,
                modelNumber: value?.modelNumber,
                projectName: value?.projectName,
                subCategory: this.dropDownValues.subCategory,
                typeNumber: value?.typeNumber,
                series: value?.series,
                purchaseDesc: value?.purchaseDescription,
                salesDesc: value?.salesDescription,
                systemPower: value?.systemWattage?.toString(),
                sourcePower: value?.sourcePower?.toString(),
                material: value?.material,
                sourceLumens: value?.sourceLumen,
                systemLumens: value?.systemLumen?.toString(),
                fittingColour: this.dropDownValues.fittingColor,
                protectionRating: this.dropDownValues.protectionRating,
                impactRating: this.dropDownValues.impactRating,
                efficacy: value?.efficacy?.toString(),
                tilt: value?.tilt,
                diffuser: value?.diffuser,
                supplyVoltage: value?.supplyVoltage,
                powerConnection: this.dropDownValues.powerConnection,
                class: this.dropDownValues.class,
                lOR: value?.LOR,
                cri: this.dropDownValues.cri,
                cct: this.dropDownValues.cct,
                beamAngle: value?.beamAngle,
                ugr: value?.UGR,
                ledLifeTime: value?.LEDLifetime,
                dimmingOptions: this.dropDownValues.dimmingOptions,
                accessories: this.dropDownValues.accessories,
                cutOut: value?.cutOut,
                rotation: value?.rotation,
                macAdam: this.dropDownValues.macAdam,
                diameter: value?.diameter,
                height: value?.height,
                length: value?.length,
                reflectorColour: this.dropDownValues.reflectorColour,
                lightSource: value?.lightSource,
                chiptype: this.dropDownValues.chipType,
                lightSourceChipType: this.dropDownValues.lightSourceChipType,
                warranty: this.dropDownValues.warranty,
                typeReference: value?.typeReference,
                vendor: this.dropDownValues.vendor,
                purchasePrice: value?.purchasePrice?.toString(),
                subsidiary: this.dropDownValues.subsidiary,
                symbols: value?.symbols,
                inSituphoto: value?.inSituphoto,
                productImage: value?.productImage,
                photometricImage: value?.photometricImage,
                measurementDiagram: value?.measurementDiagram,
                IlluminanceConeDiagram: value?.illuminanceConeDiagram,
                OtherRequirements: value?.otherRequirements,
                opticalSystem: this.dropDownValues.opticalSystem,
                certifications: this.dropDownValues.certifications,
                width: value?.width,
                energyClass: this.dropDownValues.energyClass,
                driverCode: value?.driverCode,
                operatingTemp: value?.operatingTemp,
                notes: value?.notes,
                productLength: value?.productLength,
              },
            },
            fetchPolicy: 'no-cache',
          })
          .then((result: any) => {
            if (result?.data?.createDataSheet?._id) {
              const id = result?.data?.createDataSheet?._id;
              this.graphqlService.client
                .mutate({
                  mutation: DOWNLOAD_DATA_SHEET,
                  variables: { id: id },
                  fetchPolicy: 'network-only',
                })
                .then((result) => {
                  this.isLoading = false;
                  this.openPdf = result?.data?.downloadDataSheet?.url;
                  this.toastr.success('Datasheet is generated...');
                  // this.dataSheetForm.reset();
                  // this.clearForm();
                  this.clearBtn.nativeElement.click();
                  this.openDownload();
                })
                .catch((error: any) => {
                  this.isLoading = false;
                  this.toastr.error(error.message);
                  console.error(error);
                });
            } else {
              this.isLoading = false;
              this.toastr.error('Something Went Wrong... Try Again');
            }
          })
          .catch((error: any) => {
            this.isLoading = false;
            this.toastr.error(error.message);
            console.error(error);
          });
      }
    } else {
      this.dataSheetForm.markAllAsTouched(); // Mark all fields as touched to trigger validation messages
    }
  }

  clearForm() {
    const formControls = this.dataSheetForm.controls;
    Object.keys(formControls).forEach((key) => {
      formControls[key].setValue('');
    });
  }

  openDownload(): void {
    if (this.openPdf) {
      window.open(this.openPdf, '_blank');
    } else {
      this.toastr.error('Datasheet not generate... Try Again');
    }
  }

  calculateEfficacy(): number {
    const systemLumen = this.dataSheetForm.value.systemLumen;
    const systemWattage = this.dataSheetForm.value.systemWattage;
    if (
      !isNaN(systemLumen) &&
      !isNaN(systemWattage) &&
      systemWattage !== 0 &&
      systemLumen &&
      systemWattage
    ) {
      const efficacyValue = parseFloat((systemLumen / systemWattage).toFixed());
      return efficacyValue;
    } else {
      return 0;
    }
  }

  vendorSearchText() {
    const keyword = this.vendorSearchInput.nativeElement.value
      ? this.vendorSearchInput.nativeElement.value.toLowerCase()
      : '';
    this.vendorOption = this._vendorOption.filter((_a: any) => {
      const name: string = _a.name.toLowerCase();
      return name.startsWith(keyword);
    });
  }

  placeholderText: string = 'Select';
  onSelectionChange(event: any) {
    if (event) {
      this.placeholderText = '';
    }
    // Clear the input value after selection
    this.vendorSearchInput.nativeElement.value = '';
    this.vendorOption = this._vendorOption;
  }

  symbolSearchText() {
    const keyword = this.symbolSearchInput?.nativeElement?.value
      ? this.symbolSearchInput?.nativeElement?.value?.toLowerCase()
      : '';
  
    this.symbolOption = this._symbolOption
      .sort((_a: any, _b: any) => {
        const nameA = _a?.name?.toLowerCase();
        const nameB = _b?.name?.toLowerCase();
        const startsWithA = nameA?.startsWith(keyword);
        const startsWithB = nameB?.startsWith(keyword);
        if (startsWithA && !startsWithB) return -1;
        if (!startsWithA && startsWithB) return 1;
        return nameA?.localeCompare(nameB);
      });
  }
  
  
  

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const sectionElement = document.getElementById('targetSection');
    const rect = sectionElement?.getBoundingClientRect();
    const isTopOfSection = rect?.top! <= 84;
    this.isSticky = isTopOfSection && window.pageYOffset > 84;
    if (this.isSticky) {
      sectionElement?.classList.add('targetSection-color');
    } else {
      sectionElement?.classList.remove('targetSection-color');
    }
  }

  searchDatasheet() {
    if (this.searchQuery) {
      this.searchLoading = true;
      this.graphqlService.client
        .mutate({
          mutation: SEARCH_DATA_SHEET,
          variables: { keyword: this.searchQuery },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          this.searchLoading = false;
          let data = result?.data?.searchDataSheet?.data;
          if (data && Array.isArray(data)) {
            let streets: string[] = data.map((item: any) => item?.itemid);
            this.control.markAsPristine();
            this.control.markAsUntouched();
            this.filteredStreets = of(streets);
          } else {
            throw new Error('Invalid data format.');
          }
        })
        .catch((error: any) => {
          this.searchLoading = false;
          this.toastr.error(error.message);
        });
    }
  }

  searchDatasheetDetails(event: any, int: number): any {
    this.isLoading = true;
    let itemCode;
    if (int === 1) {
      itemCode = (event.target as HTMLInputElement).value;
    } else if (int === 3) {
      itemCode = event;
    } else {
      itemCode = event;
    }
    this.graphqlService.client
      .mutate({
        mutation: DATA_SHEET_DETAILS,
        variables: { itemCode: itemCode },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const data = result?.data?.dataSheetDetails?.data;
        if (data) {
          //  update value
          this.openPdf = result?.data?.dataSheetDetails?.data?.url;
          this.dataSheetDetails = result?.data?.dataSheetDetails?.data;
          this.dataSheetFormValueUpdate(data);
        } else {
          this.toastr.error('Datasheet not found');
        }
        this.isLoading = false;
      })
      .catch((error: any) => {
        console.log(error);
        this.isLoading = false;
        this.toastr.error(error.message);
      });
  }

  dataSheetFormValueUpdate(data: any): void {
    // console.log(data);
    this.placeholderText = '';

    this.dataSheetForm.setValue({
      itemCode: data?.itemCode || '',
      purchaseDescription: data?.purchaseDesc || '',
      salesDescription: data?.salesDesc || '',
      displayName: data?.displayName || '',
      type: data?.type?.value || '',
      vendor: data?.vendor?.value || '',
      subsidiary: data?.subsidiary?.map((item: any) => item?.value) || '',
      representativeBrand: data?.representativebrand?.value || '',
      codePurchases: data?.codePurchases || '',
      purchasePrice: data?.purchasePrice || '',
      productName: data?.productName || '',
      modelNumber: data?.modelNumber || '',
      subCategory: data?.subCategory?.value || '',
      sourcePower: data?.sourcePower || '',
      systemWattage: data?.systemPower || '',
      LEDLifetime: data?.ledLifeTime || '',
      CRI: data?.cri?.value || '',
      CCT: data?.cct?.value || '',
      beamAngle: data?.beamAngle || '',
      fittingColor: data?.fittingColour?.value || '',
      productImage: data?.productImage || '',
      sourceLumen: data?.sourceLumens || '',
      protectionRating: data?.protectionRating?.value || null,
      supplyVoltage: data?.supplyVoltage || '',
      systemLumen: data?.systemLumens || '',
      impactRating: data?.impactRating?.value || '',
      accessories: data?.accessories?.map((item: any) => item?.value) || '',
      efficacy: data?.efficacy || '',
      material: data?.material || '',
      dimmingOptions:
        data?.dimmingOptions?.map((item: any) => item?.value) || '',
      opticalSystem: data?.opticalSystem?.value || '',
      certifications:
        data?.certifications?.map((item: any) => item?.value) || '',
      symbols: data?.symbols || '',
      cutOut: data?.cutOut || '',
      diameter: data?.diameter || '',
      height: data?.height || '',
      length: data?.length || '',
      tilt: data?.tilt || '',
      rotation: data?.rotation || '',
      width: data?.width || '',
      measurementDiagram: data?.measurementDiagram || '',
      diffuser: data?.diffuser || '',
      UGR: data?.ugr || '',
      LOR: data?.lOR || '',
      macAdam: data?.macAdam?.value || '',
      reflectorColour: data?.reflectorColour?.value || '',
      class: data?.class?.value || '',
      lightSource: data?.lightSource || '',
      powerConnection: data?.powerConnection?.value || '',
      energyClass: data?.energyClass?.value || '',
      chipType: data?.chiptype?.value || '',
      lightSourceChipType: data?.lightSourceChipType?.value || '',
      driverCode: data?.driverCode || '',
      warranty: data?.warranty?.value || '',
      operatingTemp: data?.operatingTemp || '',
      projectName: data?.projectName || '',
      typeReference: data?.typeReference || '',
      typeNumber: data?.typeNumber || '',
      notes: data?.notes || '',
      series: data?.series || '',
      photometricImage: data?.photometricImage || '',
      illuminanceConeDiagram: data?.IlluminanceConeDiagram || '',
      inSituphoto: data?.inSituphoto || '',
      otherRequirements: data?.OtherRequirements || '',

      productLength: data?.productLength || '',
    });
  }
}
